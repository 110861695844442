/* Define Color Variables */
:root {
  --primary-color: #EEEEEE; 
  --secondary-color: #83babd; 
  --accent-color: #e74c3c; 
  --neutral-color: #31363F;
  --text-color: #333; 
  --background-color: #222831; 
}

/* Description Section */
#description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-color: var(--neutral-color);
  color: var(--primary-color);
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;

  /* Adjust height for better responsiveness */
  min-height: auto;
}

/* Main Title */
#description h1 {
  font-size: 2.5rem; /* 40px for larger screens */
  margin-bottom: 20px;
  line-height: 1.2;
}

/* Section Styling */
#content section {
  margin-bottom: 30px;
  padding: 0 10px;
}

#content h2 {
  font-size: 2rem; /* 32px for larger screens */
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--secondary-color);
}

#content p {
  font-size: 1rem; /* 16px */
  line-height: 1.6;
  margin: 0 auto 20px;
  padding: 0 10px;
}

/* Button Guide List */
#button-guide ul {
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
}

#button-guide li {
  font-size: 1rem; /* 16px */
  margin-bottom: 10px;
  line-height: 1.5;
}

#button-guide strong {
  color: var(--secondary-color);
}

/* Responsive Styles */
@media (max-width: 768px) {
  #description {
      padding: 15px; /* Adjust padding for smaller screens */
  }

  #description h1 {
      font-size: 2rem; /* 32px */
      margin-bottom: 15px;
  }

  #content h2 {
      font-size: 1.5rem; /* 24px */
      margin-bottom: 10px;
  }

  #content p {
      font-size: 0.95rem; /* Slightly smaller font size */
      line-height: 1.5;
  }

  #button-guide li {
      font-size: 0.95rem; /* 15px */
      margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  #description {
      padding: 10px; /* Smaller padding for very small screens */
  }

  #description h1 {
      font-size: 1.75rem; /* 28px */
      margin-bottom: 10px;
  }

  #content h2 {
      font-size: 1.25rem; /* 20px */
      margin-bottom: 8px;
  }

  #content p {
      font-size: 0.875rem; /* 14px */
      line-height: 1.4;
  }

  #button-guide li {
      font-size: 0.875rem; /* 14px */
      margin-bottom: 8px;
  }
}
