:root {
  --primary-color: #EEEEEE; /* Blue */
  --secondary-color: #83babd; /* Green */
  --accent-color: #e74c3c; /* Red */
  --neutral-color: #31363F;
  --text-color: #333; /* Default text color */
  --background-color: #222831; 
}

.App {
  text-align: center;
  background-color:  #222831; 
}

html {
  scroll-behavior: smooth;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ensure the app container takes the full height */
#app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

/* Ensure content grows and pushes footer */
#main-content {
  flex: 1; /* This makes the content area take up all the available space */
}

/* Push the footer to the bottom */
#footer {
  margin-top: auto; /* Pushes footer to the bottom */
  background-color: #222831;
  color: #eeeeee;
  text-align: center;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Footer navigation styles */
#footer-nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  flex-direction: row; /* Ensure links stay horizontal */
  flex-wrap: wrap; 
}

#footer-nav a {
  color: #76abae;
  text-decoration: none;
  font-weight: bold;
}

#footer-nav a:hover {
  color: #e74c3c;
}

.footer-text {
  font-size: 14px;
  margin-top: 10px;
}

/* Add bottom margin to the description */
#description {
  margin-bottom: 100px; /* Add space between the description and footer */
}

/* Small screen adjustments */
@media (max-width: 768px) {
  #description {
    margin-bottom: 120px; /* Extra space for smaller screens */
  }

  #footer-nav {
    flex-direction: row; /* Stack navigation links */
    gap: 5px;
  }
}
